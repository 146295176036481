import React, { useEffect } from "react";
import { Col, Row, Table } from "antd";

const PreparationAreaItems = ({ items, sumbitItemToPrint, invoice }) => {
  const printPreparationArea = (items) => {
    const grouped = items.reduce((result, item) => {
      const area = item?.preparation_area[0]?.name;
      if (!result[area]) {
        result[area] = [];
      }
      result[area].push(item);
      return result;
    }, {});

    return grouped;
  };

  useEffect(() => {
    const groupedItems = printPreparationArea(items);

    // Only call the mutate function here (or in a button handler, etc.)
    sumbitItemToPrint.mutate(groupedItems);

    // You can add conditions to avoid re-triggering unnecessarily
  }, []);
  return (
    <>
      <div>
        {Object.keys(printPreparationArea(invoice?.order_items)).map((area) => (
          <>
            <div className="page-break"></div>
            <div className="basic-info" style={{ marginTop: "10px" }}>
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  <Row
                    gutter={20}
                    justify="center"
                    style={{ fontWeight: "bolder" }}
                  >
                    serial: #{invoice?.id}
                  </Row>
                </Col>
                <Col span={24}>
                  <Row
                    gutter={20}
                    justify="center"
                    style={{ fontWeight: "bolder" }}
                  >
                    {invoice?.pos_serial || "-"}
                  </Row>
                </Col>
                <Col span={24}>
                  <div>outlet: {invoice?.point_of_sale}</div>
                </Col>
                <Col span={24}>
                  <Row gutter={20} justify="space-between">
                    <Col>
                      <div>Order type: توصيل</div>
                    </Col>
                    <Col>
                      <div>date: {invoice?.created_at}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div key={area}>
              {/* Display the preparation area as the title */}
              <h2 style={{ textAlign: "center", fontWeight: "bolder" }}>
                {area}
              </h2>
              <ul>
                {/* Display the item names under the preparation area */}
                {printPreparationArea(invoice?.order_items)[area].map(
                  (item) => (
                    <Row
                      gutter={[10, 8]}
                      key={item.id}
                      justify="space-between"
                      style={{ fontSize: "14px" }}
                    >
                      <Col>{item.quantity}</Col>
                      <Col>{item.productName}</Col>
                    </Row>
                  )
                )}
              </ul>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default PreparationAreaItems;
